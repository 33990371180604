const sequence = (func, maybes) =>
  maybes.reduce(
    (acc, maybe) =>
      acc.chain(list =>
        maybe.map(val =>
          Array.isArray(val) ? list.concat([val]) : list.concat(val),
        ),
      ),
    func.of([]),
  )

module.exports = sequence
